import { ILinkFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'
import { mapSpacer } from 'src/siteBuilder/renderer/contentful/mapSpacer'
import { mapModal } from '../../../renderer/contentful/mapModal'

import Link from './Link'

const ContentfulLink = withContentful<ILinkFields>(Link, {
  spacer: mapSpacer,
  baseModal: mapModal,
  analyticsUrlUsage: (props) => props.fields.analyticsUrlUsage,
})

export default ContentfulLink
