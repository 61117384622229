import React, { useContext } from 'react'

import useKoodoAnalyticsEvent from '../../../../hooks/useKoodoAnalyticsEvent'

import { ILinkFields } from 'contentful-types'
import { SpacerProps } from '../Spacer/Spacer'

import { Link as AlliumLink } from '@telus-uds/components-web'
import { ContentfulAnalyticsEventType } from '../../../utils/types/analytics'

import { mapDataAttrsToDataSet } from '../../../../siteBuilder/utils/dataAttributes'
import { A11yTextRenderer } from '../../../utils/text/TextRenderer'
import { SiteBuilderContext } from '../../../renderer/context/SiteBuilderContext'
import BaseModal, { BaseModalProps } from '../BaseModal/BaseModal'
import useModalClick from '../../../../hooks/useModalClick'
import { BlockRenderer } from '../../../renderer/contentful/BlockRenderer'
import { getIcon } from '../../../utils/getIcon'
import { useIntcmpContent, intcmpProps } from '../../../../hooks/useIntcmpContent'
import alloyEvents from '../../../utils/alloyAnalytics/link'
import { renderSpacer } from 'src/siteBuilder/utils/renderSpacer'

export type LinkProps = {
  text: string
  url: string
  intcmpRef?: intcmpProps
  analyticsUrlUsage: ILinkFields['analyticsUrlUsage']
  analyticsEvent?: ContentfulAnalyticsEventType
  inverse?: boolean
  icon?: string
  iconPosition?: 'left' | 'right'
  alternative?: boolean
  size?: 'large' | 'medium' | 'small' | 'micro'
  target?: '_self' | '_blank' | '_parent' | '_top'
  spacer?: SpacerProps
  baseModal?: BaseModalProps
  onClick?: (e: MouseEvent) => void
}

const Link = ({
  text,
  url = '/',
  intcmpRef,
  analyticsUrlUsage,
  icon,
  iconPosition = 'left',
  analyticsEvent,
  alternative = false,
  inverse = false,
  target = '_self',
  size = 'medium',
  spacer,
  baseModal,
  onClick,
  ...rest
}: LinkProps) => {
  const [isAnalyticsTriggered, setIsAnalyticsTriggered] = React.useState(false)
  const { activeModal } = useContext(SiteBuilderContext)
  const { tryModalClick } = useModalClick(url, baseModal)

  useKoodoAnalyticsEvent(analyticsEvent, isAnalyticsTriggered)

  const handleOnClick = (e: MouseEvent) => {
    tryModalClick(e)
    setIsAnalyticsTriggered(true)
    alloyEvents.linkClick({ url, analyticsUrlUsage })
    if (onClick) onClick(e)
  }

  return (
    <div data-testid="link-testid">
      {renderSpacer(spacer)}
      {/* // TODO: Extract the base modal logic to removed repeated code in other Links */}
      {baseModal && (
        <BaseModal
          id={baseModal.id}
          entryTitle={baseModal.entryTitle}
          modalMaxWidth={baseModal.modalMaxWidth}
          heading={<BlockRenderer block={baseModal.heading as any} />}
          subHeading={<BlockRenderer block={baseModal.subHeading as any} />}
          components={<BlockRenderer block={baseModal.components as any} />}
          isOpen={activeModal === baseModal.id}
          disableAnalytics={baseModal.disableAnalytics}
        />
      )}

      <AlliumLink
        href={useIntcmpContent(url, text, intcmpRef)}
        icon={icon ? getIcon(icon) : undefined}
        iconPosition={icon ? iconPosition.toLowerCase() : undefined}
        target={target}
        onClick={handleOnClick}
        variant={{
          size: size.toLowerCase(),
          alternative,
          inverse,
        }}
        dataSet={mapDataAttrsToDataSet(rest)}
      >
        <A11yTextRenderer>{text}</A11yTextRenderer>
      </AlliumLink>
    </div>
  )
}

export default Link
